import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Stars from "./Stars"
import Button from "@components/Button/Button"
import classnames from "classnames"

const AllSocialReviews = ({
  reviews,
  language,
  heading,
  buttonHref,
  buttonText,
  isColumn,
  colorBack,
  animation,
}) => {
  const animate = animation ? "fade-up" : ""

  const wrapperClasses = classnames({
    "home-section": heading,
    "ratings-review top-down": true,
    "color-back": colorBack,
  })

  const reviewsContainer = classnames({
    "all-reviews-container-flex": isColumn,
    "all-reviews-container-grid": !isColumn,
  })

  const data = useStaticQuery(graphql`
    query SocialQuery {
      greshamJson {
        data {
          code
          platformId
          platform
          rating
          totalReviews
          url
        }
      }
      camasJson {
        data {
          code
          platform
          platformId
          rating
          totalReviews
          url
        }
      }
      milwaukieJson {
        data {
          code
          platform
          platformId
          rating
          totalReviews
          url
        }
      }
      dallesJson {
        data {
          code
          platform
          platformId
          rating
          totalReviews
          url
        }
      }
      facebookJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
    }
  `)

  let l1 = data.greshamJson.data
  let l2 = data.camasJson.data
  let l3 = data.dallesJson.data
  let l4 = data.milwaukieJson.data

  let facebook = data.facebookJson.data

  // GMB links overwrite
  l1.url =
    "https://www.google.com/search?q=beacon+oral+and+maxillofacial+surgeons+24850+SE+Stark+St%2C+Ste+100+Gresham%2C+OR+97030&rlz=1C5CHFA_enUS910US910&oq=beacon+oral+and+maxillofacial+surgeons24850+SE+Stark+St%2C+Ste+100+Gresham%2C+OR+97030&aqs=chrome..69i57.5727j0j9&sourceid=chrome&ie=UTF-8#lrd=0x549598128054e973:0x935e6b0a3c9d83dc,1"
  l2.url =
    "https://www.google.com/search?q=beacon+oral+and+maxillofacial+surgeons+5420+NW+38th+Ave%2C+Ste+103+Camas%2C+WA+98607&rlz=1C5CHFA_enUS910US910&ei=Mg1_YP3QEoSWtQWU-5DACw&oq=beacon+oral+and+maxillofacial+surgeons5420+NW+38th+Ave%2C+Ste+103+Camas%2C+WA+98607&gs_lcp=Cgdnd3Mtd2l6EAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsAMyBwgAEEcQsANQ2F5Y2F5gvmBoAXACeACAAXOIAXOSAQMwLjGYAQCgAQKgAQGqAQdnd3Mtd2l6yAEIwAEB&sclient=gws-wiz&ved=0ahUKEwj9sfO4qo3wAhUES60KHZQ9BLgQ4dUDCA8&uact=5#lrd=0x5495a4acbb14638f:0x7e4bce1038460910,1"
  l3.url =
    "https://www.google.com/search?q=beacon+oral+and+maxillofacial+surgeons+3601+Klindt+Dr%2C+Ste+100+The+Dalles%2C+OR+97058&rlz=1C5CHFA_enUS910US910&ei=QA1_YNKHDu2MtgXBx5eQCQ&oq=beacon+oral+and+maxillofacial+surgeons+3601+Klindt+Dr%2C+Ste+100+The+Dalles%2C+OR+97058&gs_lcp=Cgdnd3Mtd2l6EAM6BggAEBYQHlDBd1j8eGCKe2gAcAJ4AIABb4gBmgKSAQMyLjGYAQCgAQGgAQKqAQdnd3Mtd2l6wAEB&sclient=gws-wiz&ved=0ahUKEwjSp8W_qo3wAhVthq0KHcHjBZIQ4dUDCA8&uact=5#lrd=0x54961e90fb977563:0x6c6b17c31864416c,1"
  l4.url =
    "https://www.google.com/search?q=beacon+oral+and+maxillofacial+surgeons+2001+SE+Jefferson+St+Milwaukie%2C+OR+97222&rlz=1C5CHFA_enUS910US910&ei=UQ1_YKybHs-UtQWxnaPwDQ&oq=beacon+oral+and+maxillofacial+surgeons+2001+SE+Jefferson+St+Milwaukie%2C+OR+97222&gs_lcp=Cgdnd3Mtd2l6EANQvbwBWL28AWCBvgFoAHACeACAAWiIAbsBkgEDMS4xmAEAoAECoAEBqgEHZ3dzLXdpesABAQ&sclient=gws-wiz&ved=0ahUKEwjsh-PHqo3wAhVPSq0KHbHOCN4Q4dUDCA8&uact=5#lrd=0x549574d80b827055:0x29058de93fb43616,1"

  if (!reviews) {
    reviews = [
      { name: "Gresham", ...l1 },
      { name: "Camas", ...l2 },
      { name: "The Dalles", ...l3 },
      { name: "Milwaukie", ...l4 },
      { name: "", ...facebook },
    ]
  }

  return (
    <section className={wrapperClasses}>
      <div className="container" data-aos={animate} data-aos-duration="1200">
        {heading && (
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column is-20">
              <h2 className="has-text-centered h3-style">{heading}</h2>
            </div>
            <div className="column is-2"></div>
          </div>
        )}
        <div
          className="rating-reviews home-reviews columns is-centered iconless big-rating reviews-width"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          {/* <div className="column is-2"></div> */}
          <div
            className={reviewsContainer}
            style={{ flexDirection: isColumn ? "column" : "row" }}
          >
            {reviews &&
              reviews.map((review, i) => (
                <div className="featured-social">
                  <div>
                    {!review.name && isColumn ? (
                      <></>
                    ) : (
                      <span
                        role="presentation"
                        style={{
                          fontSize: "0.875rem",
                          lineHeight: "1.71",
                          color: "#6d6e6f",
                          display: "block",
                          marginBottom: review.name ? "-4px" : "20px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {review.name ? review.name + " Office" : ""}
                      </span>
                    )}
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      className="link-wrapper"
                      href={review.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>
                        {review.platform === "googleMyBusiness"
                          ? "Google"
                          : "Facebook"}
                      </h3>
                      <div className="total-reviews">
                        ({review.totalReviews})
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <div className="review-icons">
                        <Stars rating={review.rating} />
                      </div>
                    </a>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <span className="reviews-number">
                        {review.rating === 5 ? "5.0" : review.rating}
                      </span>
                    </a>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="column is-2"></div> */}
        </div>
      </div>

      {buttonHref && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button contained buttonText={buttonText} href={buttonHref} />
          </div>
        </div>
      )}
    </section>
  )
}

export default AllSocialReviews
